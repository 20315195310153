/**
 * On click to _report_ flag on comment element send event to server
 * in order to retrieve url from Reportform service that is used for
 * full content report.
 * When Url is received, open it in blank window.
 */
window.reportFormCommentReport = async function (postId, button) {
    const apiUrl = '/forum/report-content';
    const buttonText = button.querySelector(".btn_text");
    const csrfToken = button.dataset.csrfToken;

    button.setAttribute("disabled", true);
    buttonText.innerHTML = "nalaganje...";

    try {
        const response = await fetch(
            apiUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify({ post_id: postId })

            }
        );
        const responseData = await response.json();

        if (response.status === 201) {
            window.open(responseData.reportform_url);
        } else {
            console.error(`[Reportform] Post report endpoint '${apiUrl}' returned status ${response.status}! Data not passed.`);
        };

        button.removeAttribute("disabled");
        buttonText.innerHTML = "prijavi";
    } catch (error) {
        console.error(`[Reportform] Post report not passed to '${apiUrl}'!`);
        console.error(error);

        button.removeAttribute("disabled");
        buttonText.innerHTML = "prijavi";
    }
}
